<template>
  <div class="wrapper color-gradient overflow-hidden" ref="wrapper">
    <div class="container-fluid  sticky-top">
      <NavBar />
    </div>
    <transition name="slide-fade" mode="out-in">
      <router-view :key="$router.path"/>
    </transition>
  </div>
  <a href="#">
  <button
        type="button"
        class="btn btn-success btn-lg"
        id="btn-back-to-top"
        >
  <i class="fas fa-arrow-up"></i>
</button></a>
  <Footer />
</template>

<script>
// @ is an alias to /src
import NavBar from '../src/components/NavBar.vue'
import Footer from '../src/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    NavBar,
    Footer
  },
  methods: {
    scroll(refName) {
      const el = this.$refs[refName]
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>


<style>
:root {
  --primary-brown: #926219;
  --light-brown: #A38B53;
  --bg-white: #FBF4EE;
  --black: #1A1511;

} 
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--bg-white);
}

::-webkit-scrollbar-thumb {
  background: #a9baa7;
}

::-webkit-scrollbar-thumb:hover {
  background:  var(--third-green);
}

#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* transition: all 0.3s ease-in-out; */
  z-index: 100;
}

#btn-back-to-top:hover {
  background-color: var(--primary-yellow);
  color: var(--primary-green);
  border-color: var(--primary-yellow);
}
  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }
  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(2em);
    opacity: 0;
  }
  .min-h-screen{
    min-height:100vh !important;
  }
  

  .color-gradient {
  width: 100%;
  background-image: linear-gradient(
  to left top,
  rgba(35, 84, 66, 0.8),
  rgba(48, 96, 78, 0.8),
  rgba(60, 109, 90, 0.8),
  rgba(73, 122, 102, 0.8),
  rgba(86, 135, 115, 0.8),
  rgba(97, 147, 126, 0.8),
  rgba(109, 159, 138, 0.8),
  rgba(120, 171, 150, 0.8),
  rgba(133, 185, 163, 0.8),
  rgba(147, 199, 177, 0.8),
  rgba(161, 213, 191, 0.8),
  rgba(175, 228, 205, 0.8)
);
background-repeat: repeat-x;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  content: "";
}

.separator {
    background-image: url(assets/svg/bg.svg);
    background-size: 100% 50px;
    bottom: 0;
    height: 50px;
    width: 100%;
    z-index: 1;
    transform: scale(1,1);
}

.separator-white {
    background-image: url(assets/svg/bg-white.svg);
    background-size: 100% 50px;
    bottom: 0;
    height: 50px;
    width: 100%;
    z-index: 1;
    transform: scale(1,1);
}

.separator-light {
    background-image: url(assets/svg/bg-light.svg);
    background-size: 100% 50px;
    bottom: 0;
    height: 50px;
    width: 100%;
    z-index: 1;
    transform: scale(1,1);
}

@font-face {
  font-family: 'Inter';
  src: url('../src/assets/font/Inter-Regular.ttf') format('truetype')
}


@font-face {
  font-family: 'tomato';
  src:  url('../src/assets/font/tomato-grotesk2.woff2') format('woff2'),
        url('../src/assets/font/tomato-grotesk.woff') format('woff');
}

*{
  font-family:'tomato';
}
.primary-font{
  font-family:'tomato'!important;
}

.secondary-font{
  font-family:'inter'!important;
}
:root {
  /*lOGO COLOR*/
  --primary-green: #006634;
  --primary-yellow: #ffff00;

    /*Web Color*/
  --green: #073f38;
  --second-green: #014444;
  --third-green: #2d5f4d;
  --light-green:rgba(175, 228, 205, 0.8);
  --four-green: #00564c;
  --yellow: #fed948;
  --second-yellow: #f6f2df;
  --white: #fefeff;
  --blacl: #212121;
}

.gradient-overlay:before {
  background-repeat: repeat-x;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: "";
}
.gradient-overlay-blue:before {
  background-image: linear-gradient(
  to left top,
  rgba(35, 84, 66, 0.8),
  rgba(48, 96, 78, 0.8),
  rgba(60, 109, 90, 0.8),
  rgba(73, 122, 102, 0.8),
  rgba(86, 135, 115, 0.8),
  rgba(97, 147, 126, 0.8),
  rgba(109, 159, 138, 0.8),
  rgba(120, 171, 150, 0.8),
  rgba(133, 185, 163, 0.8),
  rgba(147, 199, 177, 0.8),
  rgba(161, 213, 191, 0.8),
  rgba(175, 228, 205, 0.8)
);
  background-repeat: repeat-x;
}
.text-yellow{
  color:var(--yellow)!important;
}
.text-green{
  color:var(--green) !important;
}
.text-justify{
  text-align: justify!important;
}

.bg-lgreen{
  background: var(--light-green)!important;
}

.w-10,
.w-xs-10 { width: 10%!important; }

.w-15,
.w-xs-15 { width: 15%!important; }

.w-20,
.w-xs-20 { width: 20%!important; }

.w-25,
.w-xs-25 { width: 25%!important; }

.w-30,
.w-xs-30 { width: 30%!important; }

.w-35,
.w-xs-35 { width: 35%!important; }

.w-40,
.w-xs-40 { width: 40%!important; }

.w-45,
.w-xs-45 { width: 45%!important; }

.w-50,
.w-xs-50 { width: 50%!important; }

.w-55,
.w-xs-55 { width: 55%!important; }

.w-60,
.w-xs-60 { width: 60%!important; }

.w-65,
.w-xs-65 { width: 65%!important; }

.w-70,
.w-xs-70 { width: 70%!important; }

.w-75,
.w-xs-75 { width: 75%!important; }

.w-80,
.w-xs-80 { width: 80%!important; }

.w-85,
.w-xs-85 { width: 85%!important; }

.w-90,
.w-xs-90 { width: 90%!important; }

.w-95,
.w-xs-95 { width: 95%!important; }

.w-100,
.w-xs-100 { width: 100%!important; }

/* BREAKPOINTS */

/* SM breakpoint */
@media (min-width: 576px) {
    /* CUSTOM WIDTHS */
    .w-sm-10 { width: 10%!important; }
    .w-sm-15 { width: 15%!important; }
    .w-sm-20 { width: 20%!important; }
    .w-sm-25 { width: 25%!important; }
    .w-sm-30 { width: 30%!important; }
    .w-sm-35 { width: 35%!important; }
    .w-sm-40 { width: 40%!important; }
    .w-sm-45 { width: 45%!important; }
    .w-sm-50 { width: 50%!important; }
    .w-sm-55 { width: 55%!important; }
    .w-sm-60 { width: 60%!important; }
    .w-sm-65 { width: 65%!important; }
    .w-sm-70 { width: 70%!important; }
    .w-sm-75 { width: 75%!important; }
    .w-sm-80 { width: 80%!important; }
    .w-sm-85 { width: 85%!important; }
    .w-sm-90 { width: 90%!important; }
    .w-sm-95 { width: 95%!important; }
    .w-sm-100 { width: 100%!important; }
}

/* MD breakpoint*/
@media (min-width: 768px) {
    /* CUSTOM WIDTHS */
    .w-md-10 { width: 10%!important; }
    .w-md-15 { width: 15%!important; }
    .w-md-20 { width: 20%!important; }
    .w-md-25 { width: 25%!important; }
    .w-md-30 { width: 30%!important; }
    .w-md-35 { width: 35%!important; }
    .w-md-40 { width: 40%!important; }
    .w-md-45 { width: 45%!important; }
    .w-md-50 { width: 50%!important; }
    .w-md-55 { width: 55%!important; }
    .w-md-60 { width: 60%!important; }
    .w-md-65 { width: 65%!important; }
    .w-md-70 { width: 70%!important; }
    .w-md-75 { width: 75%!important; }
    .w-md-80 { width: 80%!important; }
    .w-md-85 { width: 85%!important; }
    .w-md-90 { width: 90%!important; }
    .w-md-95 { width: 95%!important; }
    .w-md-100 { width: 100%!important; }
}

/* LG breakpoint */
@media (min-width: 992px) {
    /* CUSTOM WIDTHS */
    .w-lg-10 { width: 10%!important; }
    .w-lg-15 { width: 15%!important; }
    .w-lg-20 { width: 20%!important; }
    .w-lg-25 { width: 25%!important; }
    .w-lg-30 { width: 30%!important; }
    .w-lg-35 { width: 35%!important; }
    .w-lg-40 { width: 40%!important; }
    .w-lg-45 { width: 45%!important; }
    .w-lg-50 { width: 50%!important; }
    .w-lg-55 { width: 55%!important; }
    .w-lg-60 { width: 60%!important; }
    .w-lg-65 { width: 65%!important; }
    .w-lg-70 { width: 70%!important; }
    .w-lg-75 { width: 75%!important; }
    .w-lg-80 { width: 80%!important; }
    .w-lg-85 { width: 85%!important; }
    .w-lg-90 { width: 90%!important; }
    .w-lg-95 { width: 95%!important; }
    .w-lg-100 { width: 100%!important; }
}


.h-10 { height: 10%!important; }
.h-15 { height: 15%!important; }
.h-20 { height: 20%!important; }
.h-25 { height: 25%!important; }
.h-30 { height: 30%!important; }
.h-35 { height: 35%!important; }
.h-40 { height: 40%!important; }
.h-45 { height: 45%!important; }
.h-50 { height: 50%!important; }
.h-55 { height: 55%!important; }
.h-60 { height: 60%!important; }
.h-65 { height: 65%!important; }
.h-70 { height: 70%!important; }
.h-75 { height: 75%!important; }
.h-80 { height: 80%!important; }
.h-85 { height: 85%!important; }
.h-90 { height: 90%!important; }
.h-95 { height: 95%!important; }
.h-100 { height: 100%!important; }

/* HEIGHT BREAKPOINTS */

/* SM breakpoint */
@media (min-width: 576px) {
    /* CUSTOM HEIGHTS */
    .h-sm-10 { height: 10%!important; }
    .h-sm-15 { height: 15%!important; }
    .h-sm-20 { height: 20%!important; }
    .h-sm-25 { height: 25%!important; }
    .h-sm-30 { height: 30%!important; }
    .h-sm-35 { height: 35%!important; }
    .h-sm-40 { height: 40%!important; }
    .h-sm-45 { height: 45%!important; }
    .h-sm-50 { height: 50%!important; }
    .h-sm-55 { height: 55%!important; }
    .h-sm-60 { height: 60%!important; }
    .h-sm-65 { height: 65%!important; }
    .h-sm-70 { height: 70%!important; }
    .h-sm-75 { height: 75%!important; }
    .h-sm-80 { height: 80%!important; }
    .h-sm-85 { height: 85%!important; }
    .h-sm-90 { height: 90%!important; }
    .h-sm-95 { height: 95%!important; }
    .h-sm-100 { height: 100%!important; }
    .h-sm-fit {
      height:fit-content!important;
    }
}

/* MD breakpoint*/
@media (min-width: 768px) {
    /* CUSTOM HEIGHTS */
    .h-md-10 { height: 10%!important; }
    .h-md-15 { height: 15%!important; }
    .h-md-20 { height: 20%!important; }
    .h-md-25 { height: 25%!important; }
    .h-md-30 { height: 30%!important; }
    .h-md-35 { height: 35%!important; }
    .h-md-40 { height: 40%!important; }
    .h-md-45 { height: 45%!important; }
    .h-md-50 { height: 50%!important; }
    .h-md-55 { height: 55%!important; }
    .h-md-60 { height: 60%!important; }
    .h-md-65 { height: 65%!important; }
    .h-md-70 { height: 70%!important; }
    .h-md-75 { height: 75%!important; }
    .h-md-80 { height: 80%!important; }
    .h-md-85 { height: 85%!important; }
    .h-md-90 { height: 90%!important; }
    .h-md-95 { height: 95%!important; }
    .h-md-100 { height: 100%!important; }
}

/* LG breakpoint */
@media (min-width: 992px) {
    /* CUSTOM HEIGHTS */
    .h-lg-10 { height: 10%!important; }
    .h-lg-15 { height: 15%!important; }
    .h-lg-20 { height: 20%!important; }
    .h-lg-25 { height: 25%!important; }
    .h-lg-30 { height: 30%!important; }
    .h-lg-35 { height: 35%!important; }
    .h-lg-40 { height: 40%!important; }
    .h-lg-45 { height: 45%!important; }
    .h-lg-50 { height: 50%!important; }
    .h-lg-55 { height: 55%!important; }
    .h-lg-60 { height: 60%!important; }
    .h-lg-65 { height: 65%!important; }
    .h-lg-70 { height: 70%!important; }
    .h-lg-75 { height: 75%!important; }
    .h-lg-80 { height: 80%!important; }
    .h-lg-85 { height: 85%!important; }
    .h-lg-90 { height: 90%!important; }
    .h-lg-95 { height: 95%!important; }
    .h-lg-100 { height: 100%!important; }
}
.fs-6 {font-size:0.9rem !important;}
@media (min-width: 576px) {
  .fs-sm-1 { font-size: 2.5rem !important; }
  .fs-sm-2 { font-size: 2rem !important; }
  .fs-sm-3 { font-size: 1.75rem !important; }
  .fs-sm-4 { font-size: 1.5rem !important; }
  .fs-sm-5 { font-size: 1.25rem !important; }
  .fs-sm-6 {font-size:0.9rem !important;}
}

@media only screen and (min-width: 768px) {
  .fs-md-1 { font-size: 2.5rem !important; }
  .fs-md-2 { font-size: 2rem !important; }
  .fs-md-3 { font-size: 1.75rem !important; }
  .fs-md-4 { font-size: 1.5rem !important; }
  .fs-md-5 { font-size: 1.25rem !important; }
  .fs-md-6 {font-size:0.9rem !important;}
  .fs-md-7 {font-size:1rem !important;}
}

@media only screen and (min-width: 992px) {
  .fs-lg-1 { font-size: 2.5rem !important; }
  .fs-lg-2 { font-size: 2rem !important; }
  .fs-lg-3 { font-size: 1.75rem !important; }
  .fs-lg-4 { font-size: 1.5rem !important; }
  .fs-lg-5 { font-size: 1.25rem !important; }
  .fs-lg-6 {font-size:0.9rem !important;}
}

@media only screen and (min-width: 1200px) {
  .fs-xl-1 { font-size: 2.5rem !important; }
  .fs-xl-2 { font-size: 2rem !important; }
  .fs-xl-3 { font-size: 1.75rem !important; }
  .fs-xl-4 { font-size: 1.5rem !important; }
  .fs-xl-5 { font-size: 1.25rem !important; }
  .fs-xl-6 {font-size:0.9rem !important;}
}

</style>
