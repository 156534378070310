import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

import AOS from "aos";
import 'aos/dist/aos.css';

const routes = [
  // { path: "*", component: HomeView },
  { 
    path: '/:pathMatch(.*)', 
    redirect: '/'
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/investment-insight',
    name: 'investment-insight',
    component: () => import('../views/InvestmentView.vue')
  },
  {
    path: '/csr',
    name: 'csr',
    component: () => import('../views/CsrView.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('../views/ContactUsView.vue')
  },
  {
    path: '/business-pillar',
    name: 'business-pillar',
    component: () => import('../views/bpView.vue')
  },
  {
    path: '/management-team',
    name: 'management-team',
    component: () => import('../views/ManagementTeamView.vue')
  },
  {
    path: '/term-and-condition',
    name: 'term-and-condition',
    component: () => import('../views/TermAndCondition.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/FaqView.vue'),
  },
  {
    path: '/e-brochure',
    name: 'brochure',
    component: () => import('../views/CatalogView.vue'),
  },
  {
    path: '/milestone',
    name: 'milestone',
    component: () => import('../views/MilestoneView.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import('../views/BlogView.vue')
  },
  {
    path: '/gallery-detail/:id',
    name: 'gallery-detail',
    component: () => import('../views/BlogDetailView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
})

router.beforeEach((to, from, next) => {
  AOS.init();
  next();

});

export default router
