<template>
    
<div class="modal" ref="modal" id="modal" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">

        <div class="modal-content position-relative p-3">
            <button type="button" class="btn-close position-absolute end-0 me-3 " data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="p-4 d-flex align-items-start pb-0 mb-0">
                <div class="col">
                    <div class="row">
                        <h4 class="modal-title fw-bold pt-0" style="font-weight: 700px !important; color: var(--primary-green)">Term and Condition</h4>
                    </div>
                    <div class="row">
                        
                    </div>
                </div>
                
            </div>
            <hr class="px-4 mx-4 pt-0 mt-0" style="border-top: 1px solid gray;">
            <div class="px-4 d-flex align-items-start mb-0">
                <p class="secondary-font text-black pb-0 mb-4 small">
                    We will use the information you provide on this form to be in touch with you and to provide updates 
                    and marketing. We will treat your information with respect.
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            state:{
                modal_demo: null
            }
        }
    },
    setup(){
    },
    mounted(){
        this.state.modal_demo = new bootstrap.Modal(document.getElementById('modal'), {})
     
        // this.state.modal_demo.show()
    },
    methods:{
        closeModal() {
            this.state.modal_demo.hide();
        },
        openModal(){
            this.state.modal_demo.show()
        },
    }
}
</script>

<style>
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: black;
            opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: black;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
            color: black;
 }
</style>