<template>
    <footer class="text-white text-center text-lg-start" style="background-color: #23242a;">
        <TermCondition ref="termModal"/>
        <!-- Grid container -->
        <div class="container-fluid py-5 px-3 pb-3">
            <!--Grid row-->
            <div class="row ">
                <!--Grid column-->
                <div class="col-lg-3 col-md-12 mb-3 mb-md-0">
                    <img src="../assets/logo-wiraraja.png" alt="Wiraraja" height="80" class="mb-3" >

                    <p class="secondary-font px-3 text">
                         Wiraraja Indonesia aims to continuously innovate towards a “Golden Indonesia”.
                    </p>
                    <div class="my-4 px-2 row d-flex justify-content-lg-start align-items-lg-center justify-content-center mx-0 ms-0 ">
                        <!-- Instagram -->
                        <div class="col-sm-auto col-2 m-2 p-0">
                            <a type="button" target="_blank" href="https://www.instagram.com/wirarajaindonesia/" class="btn btn-floating btn-light btn-lg btn-circle"><Icon icon="mdi:instagram" /></a>
                        </div>
                        <!-- Linkedin -->
                        <div class="col-sm-auto col-2 m-2 p-0">
                            <a type="button" target="_blank" href="https://www.linkedin.com/company/wiraraja/?originalSubdomain=id" class="btn btn-floating btn-light btn-lg btn-circle"><Icon icon="mdi:linkedin" /></a>
                        </div>
                        <!-- Youtube -->
                        <div class="col-sm-auto col-2 m-2 p-0">
                            <a type="button" target="_blank" href="https://www.youtube.com/channel/UCvSX7Gp1aFFRiKKpKH_pWhQ" class="btn btn-floating btn-light btn-lg btn-circle"><Icon icon="mdi:youtube" /></a>
                        </div>
                    </div>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-3 col-md-12 mb-4 mb-md-0 me-lg-0 pe-lg-0">
                    <h5 class="mb-3">INFORMATION</h5>
                        <ul class="list-unstyled mb-4">
                            <li class="">
                                <a href="/gallery" class="footer-link secondary-font">Gallery</a>
                            </li>
                            <li class="">
                                <a href="/faq" class="footer-link secondary-font">FAQ</a>
                            </li>
                            <li class="">
                                <a href="https://career.wiraraja.com/" target="_blank" class="footer-link secondary-font">Career</a>
                            </li>
                            <li class="">
                                <a href="/e-brochure" class="footer-link secondary-font">E-Brochure</a>
                            </li>
                            <li class="mb-1">
                                <a href="/term-and-condition" class="footer-link secondary-font">Terms and Conditions</a>
                            </li>
                            <!-- <li class="mb-1">
                                <a href="#" class="footer-link secondary-font">Privacy and Policy</a>
                            </li> -->
                        </ul>
                </div>
                <!--Grid column-->
                <!--Grid column-->
                <div class="col-lg-3 col-md-12 mb-4 mb-md-0" >
                    <h5 class="text-uppercase mb-3 font-weight-bold">Our Location</h5>
                    <a
                    target="_blank"
                    style="text-decoration: none;" 
                    class="text-white"
                    href="https://www.google.com/maps/place/Wiraraja+Indonesia/@1.0568148,104.1254318,17z/data=!3m1!4b1!4m6!3m5!1s0x31d985dd66cd2ef3:0x57199c7fbea76512!8m2!3d1.0568094!4d104.1280067!16s%2Fg%2F11h1ltr7l3?entry=ttu">
                        <div class="row">
                            <h6 class="" style="color:#A8D3B0">HEAD OFFICE</h6>
                            <p style="font-size: small;" class="secondary-font mb-1">Jl. Wiraraja Industrial Estate</p>
                            <p style="font-size: small;" class="secondary-font mb-1">Blok A No. 1 - 5, Kabil, Nongsa,</p>
                            <p style="font-size: small;" class="secondary-font mb-1">Batam City, Riau Islands</p>
                            <p style="font-size: small;" class="secondary-font mb-2">Indonesia 29467</p>
                            <!-- <p style="font-size: small;"><i class="fas fa-print mr-3"></i> + 01 234 567 89</p> -->
                        </div>
                    </a>
                    
                    <!-- <div class="row mb-4 mt-3">
                        <h6 class="" style="color:#A8D3B0">REPRESENTATIVE OFFICE</h6>
                        <p style="font-size: small;" class="secondary-font mb-1">Shinjuku Nomura Building 32F</p>
                        <p style="font-size: small;" class="secondary-font mb-1">1-26-2 Nishi-Shinjuku, Shinjuku-ku</p>
                        <p style="font-size: small;" class="secondary-font mb-2">Tokyo 163-0532, Japan</p>
                    </div> -->
                </div>
                <!--Grid column-->
                <!--Grid column-->
                <div class="col-lg-3 col-md-12 mb-4 mb-md-0">
                    <h5 class="text-uppercase mb-3 font-weight-bold">Contact Us</h5>
                    <div>
                        <!-- <span class="fa-li"><Icon icon="ic:baseline-home" /></span><span class="ms-2 secondary-font " style="font-size: small;">Batam, Riau Islands, Indonesia 29467</span> -->
                    </div>
                    <a
                    href="mailto:market@wiraraja.com"
                    target="_blank"
                    style="text-decoration: none;" 
                    class="text-white">
                        <div>
                            <span><Icon class="mb-1" icon="ic:outline-email" /></span><span class="ms-2 secondary-font " style="font-size: small;">market@wiraraja.com</span>
                        </div>
                    </a>
                    <a
                    href="tel:+627787100798"
                    target="_blank"
                    style="text-decoration: none;" 
                    class="text-white">
                        <div>
                            <span><Icon class="mb-1" icon="ic:baseline-phone" /></span><span class="ms-2 secondary-font " style="font-size: small;">(+62) 778-7100798</span>
                        </div>
                    </a>
                    <div class="row">
                        <h5 class="text-uppercase my-3 mt-4 mt-lg-3 font-weight-bold">OPERATIONAL HOUR</h5>
                        <p class="secondary-font my-0 py-0 small">Mon - Fri : 08:00 - 17:00 (GMT+7)</p>
                        <p class="secondary-font my-0 py-0 small">Sat : 08:00 - 12:00 (GMT+7)</p>
                        <p class="secondary-font my-0 py-0 small">Closed on Sunday & PH</p>
                    </div>
                </div>
                <!--Grid column-->
            </div>
            <!--Grid row-->
        </div>
        <!-- Grid container -->

        <!-- Copyright -->
        <div class="text-center p-3 secondary-font " style="background-color: rgba(0, 0, 0, 0.2);">
            Copyright © {{ new Date().getFullYear() }} 
            Wiraraja Indonesia
        </div>
        <!-- Copyright -->
    </footer>

</template>

<script>
import TermCondition from '@/components/TermCondition.vue'
import { Icon } from '@iconify/vue';
export default {
    components:{
        Icon,
        TermCondition
    },
}
</script>

<style scoped>

    .footer-link{
        color: white;
        -webkit-transition: color 0.3s; /* For Safari 3.0 to 6.0 */
        transition: color 0.3s;
    }
    .footer-link:hover{
        color: var(--primary-green);
    }

    .btn-circle {
        background-color: var(--primary-green);
        border-color: var(--primary-green);
        color: white;
        width: 55px;
        height: 55px;
        padding: 4px 0px;
        border-radius: 30px;
        text-align: center;
        font-size: 25px;
        -webkit-transition: border-color 0.3s linear, background-color 0.3s linear;
        -ms-transition: border-color 0.3s linear, background-color 0.3s linear;
        transition: border-color 0.3s linear, background-color 0.3s linear;
    }

    .btn-circle:hover{
        background-color: var(--primary-yellow);
        border-color: var(--primary-yellow);
        color: var(--primary-green);
    }

    @media screen and (max-width: 1025px  ) {
        .btn-circle {
            width: 50px;
            height: 50px;
            padding: 4px 0px;
            border-radius: 27px;
            text-align: center;
            font-size: 22px;
        }
    }

    @media screen and (max-width: 321px  ) {
        .btn-circle {
            width: 35px;
            height: 35px;
            padding: 3px 0px;
            border-radius: 18px;
            text-align: center;
            font-size: 15px;
        }
    }
</style>