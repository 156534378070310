import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import AOS from 'aos'
import { Icon } from '@iconify/vue';
import 'aos/dist/aos.css'
import Vue3Lottie from 'vue3-lottie'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import Vue3Autocounter from 'vue3-autocounter';
import VueSplide from '@splidejs/vue-splide';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import Vue3Toastify from 'vue3-toastify';

const app = createApp(App);
app.component('VueDatePicker', VueDatePicker);

app.use(router).use(AOS).use(Icon).use(Vue3Lottie).use( VueSplide ).use(autoAnimatePlugin).component('vue3-autocounter', Vue3Autocounter).use(Vue3Toastify, {autoClose: 3000}).mount('#app')
